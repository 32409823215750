function getAbrevMonth(theMonth) {
	var tmpMonth = '';
	switch (parseInt(theMonth.substring(5, 7))) {
		case 1:
			tmpMonth = 'Ene';
			break;
		case 2:
			tmpMonth = 'Feb';
			break;
		case 3:
			tmpMonth = 'Mar';
			break;
		case 4:
			tmpMonth = 'Abr';
			break;
		case 5:
			tmpMonth = 'May';
			break;
		case 6:
			tmpMonth = 'Jun';
			break;
		case 7:
			tmpMonth = 'Jul';
			break;
		case 8:
			tmpMonth = 'Ago';
			break;
		case 9:
			tmpMonth = 'Sep';
			break;
		case 10:
			tmpMonth = 'Oct';
			break;
		case 11:
			tmpMonth = 'Nov';
			break;
		case 12:
			tmpMonth = 'Dic';
			break;
		default:
			break;
	}
	return tmpMonth;
}
function dame_color_aleatorio() {
	let hexadecimal = new Array('0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F');
	let color_aleatorio = '#';
	for (let i = 0; i < 6; i++) {
		var posarray = aleatorio(0, hexadecimal.length);
		color_aleatorio += hexadecimal[posarray];
	}
	return color_aleatorio;
}
function aleatorio(inferior, superior) {
	let numPosibilidades = superior - inferior;
	let aleat = Math.random() * numPosibilidades;
	aleat = Math.floor(aleat);
	return parseInt(inferior) + aleat;
}

module.exports = {
	getAbrevMonth,
	dame_color_aleatorio,
};

<template>
  <b-container fluid>
    
    <b-container>
      <b-row class="mb-3">
        <b-col>
          <h4>Consultar Estadísticas</h4>
        </b-col>
      </b-row>
      <!-- <b-row class="mb-3">
        <b-col class="text-left">
          <label><b>Sede</b></label>
          <b-form-select
            v-model="placeSel"
            :options="placesOpt"
            placeholder="Seleccione una sede"
          ></b-form-select>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col class="text-left">
          <label for="example-datepicker-desde"><b>Fecha Desde</b></label>
          <b-form-datepicker
            id="example-datepicker-desde"
            class="mb-2"
            placeholder="Seleccione una fecha"
            v-model="dateIniSel"
          ></b-form-datepicker>
        </b-col>
        <b-col class="text-left">
          <label for="example-datepicker-hasta"><b>Fecha Hasta</b></label>
          <b-form-datepicker
            id="example-datepicker-hasta"
            class="mb-2"
            placeholder="Seleccione una fecha"
            v-model="dateEndSel"
          ></b-form-datepicker>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-button variant="primary" @click="consultar()"><b-icon icon="graph-up"></b-icon> Graficar <br><b-icon
        icon="arrow-clockwise"
        animation="spin"
        font-scale="2"
        v-if="isBussy"
      ></b-icon></b-button>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col>
          <b-container>
            <b-row align-h="start" class="mt-4">              
              <b-col  sm="3" md="3" lg="3" xl="3">
                <b-table small bordered hover :items="tblData" foot-clone style="font-size: 0.8rem !important;" responsive table-variant="primary">
                  <template #foot(sede)="data">
                    <span class="text-danger">Total:</span>
                  </template>
                  <template #foot(cantidad)="data">
                    <span class="text-danger">{{ totalGrafica }}</span>
                  </template>
                </b-table>
              </b-col>   
              <b-col cols="12" sm="9" md="9" lg="9" xl="9">
                <bar-chart class="bar-chart" ref="barChart"></bar-chart>
              </b-col>     
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import BarChart from "../components/BarChart";
import { getAbrevMonth, dame_color_aleatorio } from "../libs/funciones";
import xlsx from "xlsx";
export default {
  components: { BarChart },
  data() {
    return {
      dateSel: null,
      isBussy: false,
      placesOpt: [],
      placeSel: null,
      dateIniSel: null,
      dateEndSel: null,
      tblData: []
    };
  },
  mounted() {
    if (!localStorage.getItem("emailLogged")) {
      this.$router.replace("/login");
    }
    this.setLayout("AdminLayout");
    // this.getPlacesAvailables();
  },
  computed: {
    ...mapState(["userLogged"]),
    ...mapState("reserves", ["reservesByDate"]),
    ...mapState("places", ["places"]),
    totalGrafica(){
      return this.tblData.reduce((acc,item) => acc + item.cantidad,0)
    }
  },
  methods: {
    ...mapActions(["setLayout"]),
    ...mapActions("reserves", [
      "getReservesByDate",
      "getReservesByDatePrincipal",
      "setPlaceSelected",
      "getReservesExport",
    ]),
    ...mapActions("places", ["getPlaces"]),
    async getPlacesAvailables() {
      await this.getPlaces();
      this.placesOpt.push({
        value: null,
        text: "Todas",
      });
      this.places.forEach((element) => {
        this.placesOpt.push({
          value: element.name,
          text: element.name + " => " + element.adress,
        });
      });
      this.isBussy = false;
    },
    getIdPlace(namePlace) {
      const place = this.places.filter((el) => {
        if (el.name == namePlace) {
          return el;
        }
      });
      return place[0].id;
    },
    async consultar() {
      if (!this.dateIniSel || !this.dateEndSel) {
        this.$toastr.e("Debe seleccionar un rango de fechas", "Exportar excel");
        return;
      }

      if (this.dateIniSel > this.dateEndSel) {
        this.$toastr.e(
          "Las fechas seleccionadas no son válidas",
          "Exportar excel"
        );
        return;
      }
      this.isBussy = true;
      this.getReservesExport({
        dateIni: this.dateIniSel,
        dateEnd: this.dateEndSel,
        place: this.placeSel ? this.getIdPlace(this.placeSel) : null,
      }).then((res) => {
        let tmpData = res.map((el) => {
          let nombreSede = this.places.find((e) => e.id == el.sede);
          return {
            ...el,
            nombre: el.nombre.toUpperCase(),
            fecha: moment(el.fecha).format("DD/MM/YYYY"),
            sede: nombreSede ? nombreSede["name"] : '',
          };
        });        
        this.graficar(tmpData);
      });
    },
    graficar(report_info) {
      this.tblData = []
      const report_labels = this.places.map(e => e.etiqueta);
      let data = report_info.map(e => {
        let sede = this.places.find(x => x.name == e.sede);
        if(sede){
          return sede.etiqueta
        }else{
          return ''
        }
      }).reduce((obj,sede) => {
        if(obj[sede]){
          obj[sede] = obj[sede] + 1;
        }else{
          obj[sede] = 1;
        }
        return obj;
      },{});
      
      let itemsData = [];
      report_labels.forEach(e => {        
        let valor = eval(`data.${e}`) ? eval(`data.${e}`) : 0
        this.tblData.push({
          sede: e,
          cantidad: valor
        });
        itemsData.push(valor); 
      });

      const report_data = itemsData;
      
      const colors = this.places.map((item) => {
        return dame_color_aleatorio();
      });

      var maxValue = Math.max(...report_data);

      this.chartData = {
        labels: report_labels,
        datasets: [
          {
            // label: "Enviados",
            data: report_data,
            backgroundColor: colors,
            // borderColor: "#1ab187",
            borderWidth: 1,
            barThickness: 25,
          },
        ],
      };
      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 50,
            bottom: 20,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
                suggestedMax: maxValue * 1.1,
                beginAtZero: true,
              },
            },
          ],
        },
      };
      this.isBussy = false;
      if (this.$refs.barChart) {
        this.$refs.barChart.render(this.chartData, this.chartOptions);
      }
      
    },
  },
};
</script>
<style lang="scss" scoped></style>

<script>
	import { Bar } from 'vue-chartjs';
	export default {
		extends: Bar,
		mounted() {},

		methods: {
			render(chartData, chartOptions) {
				this.renderChart(chartData, chartOptions);
			},
		},
	};
</script>

<style></style>
